<template>
    <section id="dashboard-fiordo-reasignacion">

        <b-row class="mb-2">
            <b-col sm="8">
                <b-form-select v-model="selected" :options="options"></b-form-select>
            </b-col>
        </b-row>
        
        <div v-for="item in data_contrato" :key="item.title">
            <b-row>
                <b-col sm="6">
                    <ul class="list-group list-group-horizontal">
                        <li class="list-group-item w-25"><b>{{item.title}}</b></li>
                        <li class="list-group-item w-75">{{item.description}}</li>
                    </ul>
                </b-col>
            </b-row>
        </div>

        <b-row>
            <b-col sm="12">
                <ag-grid-table
                :configUrl="config02"
                :dataUrl="data02"
                :editUrl="edit02">
                </ag-grid-table>
            </b-col>
        </b-row>

        <b-row>
            <b-col sm="12">
                <ag-grid-table
                :configUrl="config03"
                :dataUrl="data03"
                :editUrl="edit03">
                </ag-grid-table>
            </b-col>
        </b-row>

    </section>
</template>
<script>
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import ReemplazarActions from "./ReemplazarActions.vue";
import useApiServices from '@/services/useApiServices.js';
import
{
  BRow,
  BCol,
} from "bootstrap-vue";
export default {  
    components: {
        AgGridTable,
        BRow,
        BCol,
        reemplazarActions: ReemplazarActions
    },
    methods: { 
        
    },
    mounted()
    {
      
    },
    data()
    {
        return {
            selected: null,
            options: [
            { value: null, text: 'Please select an option' },
            { value: 'a', text: 'This is First option' },
            { value: 'b', text: 'Selected Option' },
            { value: { C: '3PO' }, text: 'This is an option with object value' },
            { value: 'd', text: 'This one is disabled', disabled: true }
            ],

            data_contrato: [
            { title: 'contrato', description: 'Please select an option' },
            { title: 'Cliente', description: 'This is First option' },
            { title: 'b', description: 'Selected Option' },
            { title: 'Destino', description: 'This is an option with object value' },
            { title: 'Fecha Inicio', description: 'This one is disabled'},
            { title: 'Fecha Fin', description: 'This one is disabled'},
            { title: 'Meses', description: 'This one is disabled'},
            { title: 'Toneladas', description: 'This one is disabled'},
            { title: 'Calidad', description: 'This one is disabled'},
            { title: 'Parametros', description: 'This one is disabled'}
            ],

            config01: useApiServices.reasignacionLotesConfig01,
            data01: useApiServices.reasignacionLotesData01,
            edit01: useApiServices.reasignacionLotesEdit01,

            config02: useApiServices.reasignacionLotesConfig02,
            data02: useApiServices.reasignacionLotesData02,
            edit02: useApiServices.reasignacionLotesEdit02,

            config03: useApiServices.reasignacionLotesConfig03,
            data03: useApiServices.reasignacionLotesData03,
            edit03: useApiServices.reasignacionLotesEdit03,
        };
    },
};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>